import PropTypes from "prop-types";
import { useState } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Card,
  Alert,
  TextField,
  Stack,
  CardHeader,
  Box,
  Autocomplete,
  Typography,
  Grid,
  Chip,
  Divider,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
  createFilterOptions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { CheckPerm, VARIETIES } from "../../../utils/apis";

// ----------------------------------------------------------------------

DeleteAttribute.propTypes = {
  handleRemoval: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
};
function DeleteAttribute({ open, loading, handleRemoval, name, onClose }) {
  return (
    <Dialog open={open} maxWidth="xs" onClose={onClose}>
      <DialogTitle>
        Remove &quot;{String(name).toLocaleUpperCase()}&quot; from varieties?
      </DialogTitle>
      <DialogContent sx={{ width: { xs: "100%", md: 400 } }}>
        <DialogContentText sx={{ mb: 5 }}>
          Please confirm you want to remove this variety.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LoadingButton
            fullWidth
            loading={loading}
            variant="contained"
            onClick={handleRemoval}
          >
            Yes, Proceed
          </LoadingButton>
          <Button fullWidth onClick={onClose} color="inherit">
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
ProductVarieties.propTypes = {
  uid: PropTypes.string,
  myProfile: PropTypes.object,
};
const filter = createFilterOptions();
export default function ProductVarieties({ uid, myProfile }) {
  const { updateProductVarieties } = useAuth();
  const [openRemoval, setOpenRemoval] = useState(false);
  const [loadingRemoval, setLoadingRemoval] = useState(false);
  const [selectRemoval, setSelectedRemoval] = useState("");
  const [options, setOptions] = useState([]);
  const [customOption, setCustomOption] = useState("");

  const hasPermission = CheckPerm("update_products");
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoval = async () => {
    try {
      setLoadingRemoval(true);
      const varieties = values.varieties.filter(
        (variety) =>
          String(variety.name).toLocaleLowerCase() !==
          String(selectRemoval).toLocaleLowerCase()
      );
      await updateProductVarieties(varieties, uid);
      setFieldValue("varieties", varieties);
      enqueueSnackbar(`Product's variety has been removed!`, {
        variant: "success",
      });
      handleCloseRemoval();
    } catch (err) {
      enqueueSnackbar(err.message || err || "An error has occurred", {
        variant: "error",
      });
    }
    setLoadingRemoval(false);
  };
  const handleCloseRemoval = () => {
    setOpenRemoval(false);
    setSelectedRemoval("");
  };

  const Schema = Yup.object().shape({
    varieties: Yup.array().required("Add atleast one variety to proceed."),
  });

  const formik = useFormik({
    initialValues: {
      varieties: myProfile.varieties ? JSON.parse(myProfile.varieties) : [],
      type: { name: "", value: "" },
      options: [],
    },
    validationSchema: Schema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (values.type && values.type.value && values.options.length > 0) {
          const { varieties } = values;
          varieties.push({ name: values.type.value, value: values.options });
          await updateProductVarieties(varieties, uid);
          setFieldValue("varieties", varieties);
          enqueueSnackbar(`Product's variety has been added!`, {
            variant: "success",
          });
          setFieldValue("type", { name: "", value: "" });
          setFieldValue("options", []);
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        } else {
          setErrors({
            afterSubmit:
              "Please select the variety type you want to add and atleast one option to proceed.",
          });
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, values, setFieldValue } =
    formik;

  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        px: 3,
        pb: 3,
      }}
    >
      <CardHeader
        title="Product's Varieties"
        subheader="You can specify the different varieties this particular
                  product has such sizes, colours, shapes etc."
      />
      <Box sx={{ p: { md: 3, xs: 1 } }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction="column" spacing={2}>
                <TableContainer>
                  <Table>
                    <TableHead
                      sx={{
                        borderBottom: (theme) =>
                          `solid 1px ${theme.palette.divider}`,
                        "& th": { backgroundColor: "transparent" },
                      }}
                    >
                      <TableRow>
                        <TableCell align="left">Variety</TableCell>
                        <TableCell align="right">Option(s)</TableCell>
                        <TableCell colSpan={1} />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {(values.varieties.length > 0 &&
                        values.varieties.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              borderBottom: (theme) =>
                                `solid 1px ${theme.palette.divider}`,
                            }}
                          >
                            <TableCell align="left">
                              <Box sx={{ maxWidth: 560 }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {row.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="right">
                              {row.value.join(", ")}
                            </TableCell>
                            <TableCell>
                              <Tooltip title="Remove variety">
                                <IconButton
                                  size="small"
                                  color="error"
                                  onClick={() => {
                                    setSelectedRemoval(
                                      String(row.name).toLocaleLowerCase()
                                    );
                                    setOpenRemoval(true);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))) || (
                        <TableCell align="center" colSpan={3}>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "error.main", textAlign: "center" }}
                          >
                            No variety added yet
                          </Typography>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              <Divider flexItem orientation="horizontal" />
              {errors.afterSubmit && (
                <Alert severity="error">{errors.afterSubmit}</Alert>
              )}
              <Autocomplete
                disabled={!hasPermission}
                sx={{ maxWidth: 1, width: 1 }}
                value={(values.type && values.type.name) || ""}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      value: inputValue,
                      name: `Add "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                renderOption={(props, option) => {
                  // eslint-disable-next-line react/prop-types
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      {option.name}
                    </li>
                  );
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={VARIETIES}
                freeSolo
                onChange={(event, newValue) => {
                  console.log(newValue);
                  if (newValue && newValue.value) {
                    const name = String(newValue.name).startsWith('Add "')
                      ? String(newValue.name)
                          .replace('Add "', "")
                          .replace('"', "")
                      : newValue.name;
                    setFieldValue("type", { ...newValue, name });
                  } else if (newValue) {
                    setFieldValue("type", { name: newValue, newValue });
                  } else {
                    setFieldValue("type", { name: "", value: "" });
                  }
                  setFieldValue("options", []);
                  setOptions(
                    typeof newValue === "string" ? [] : newValue.options || []
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Variety"
                    variant="outlined"
                    error={Boolean(touched.type && errors.type)}
                    helperText={
                      (touched.type && errors.type) ||
                      "Select a variety to add."
                    }
                  />
                )}
              />
              {values.type && values.type.name && (
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ mb: 2, color: "text.secondary" }}
                  >
                    Select the appropriate options available for this variety.
                  </Typography>
                  {VARIETIES.filter(
                    (variety) => variety.value === values.type.value
                  ).length > 0 &&
                    VARIETIES.filter(
                      (variety) => variety.value === values.type.value
                    )[0].options.length > 10 &&
                    hasPermission && (
                      <TextField
                        fullWidth
                        size="small"
                        label="Search options"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          const search = String(
                            e.target.value
                          ).toLocaleLowerCase();
                          const allOptions = VARIETIES.filter(
                            (variety) => variety.value === values.type.value
                          )[0].options;
                          if (search) {
                            setOptions(
                              allOptions.filter((option) =>
                                option.toLocaleLowerCase().includes(search)
                              )
                            );
                          } else {
                            setOptions(allOptions);
                          }
                        }}
                      />
                    )}
                  <Grid container spacing={1} sx={{ mt: 2 }}>
                    {(options.length > 0 &&
                      options.map((option, index) => (
                        <Grid item key={index}>
                          <Chip
                            color={
                              values.options.includes(option)
                                ? "success"
                                : "default"
                            }
                            label={option}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              const { options } = values;
                              if (options.includes(option)) {
                                const index = options.indexOf(option);
                                if (index > -1) {
                                  options.splice(index, 1);
                                }
                              } else {
                                options.push(option);
                              }
                              setFieldValue("options", options);
                            }}
                          />
                        </Grid>
                      ))) || (
                      <Grid item>
                        <Typography
                          variant="body2"
                          sx={{ color: "error.main", textAlign: "center" }}
                        >
                          No option matches your search string!
                        </Typography>
                      </Grid>
                    )}
                    <Grid item md={12} xs={12}>
                      <FormControlLabel
                        label={`Enter custom ${values.type.name}`}
                        labelPlacement="top"
                        sx={{ alignItems: "start", py: 2, mx: 0 }}
                        control={
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <TextField
                              value={customOption}
                              onChange={(e) =>
                                setCustomOption(e.target.value || "")
                              }
                              size="small"
                            />
                            <Button
                              onClick={() => {
                                if (customOption) {
                                  setOptions((prev) => [...prev, customOption]);
                                  setFieldValue("options", [
                                    ...values.options,
                                    customOption,
                                  ]);
                                  setCustomOption("");
                                } else {
                                  enqueueSnackbar(
                                    `Please enter a valid option to proceed.!`,
                                    {
                                      variant: "error",
                                    }
                                  );
                                }
                              }}
                              variant="contained"
                              color="info"
                              size="small"
                            >
                              Add
                            </Button>
                          </Stack>
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Stack>
            {hasPermission && (
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                Add Variety
              </LoadingButton>
            )}
          </Form>
        </FormikProvider>
      </Box>
      <DeleteAttribute
        open={openRemoval}
        loading={loadingRemoval}
        handleRemoval={handleRemoval}
        name={selectRemoval}
        onClose={handleCloseRemoval}
      />
    </Card>
  );
}
