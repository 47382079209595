import PropTypes from "prop-types";
import { useState } from "react";
// material
import {
  Box,
  Grid,
  Card,
  Button,
  Typography,
  Stack,
  DialogActions,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { fCurrency } from "../../../utils/formatNumber";
import { fDate } from "../../../utils/formatTime";
import useAuth from "../../../hooks/useAuth";
import { ShowError } from "../../../utils/loader";

// ----------------------------------------------------------------------

SingleCard.propTypes = {
  debt: PropTypes.object,
  handleRemove: PropTypes.func,
};

function SingleCard({ debt, handleRemove }) {
  const { amount, reason, date } = debt;
  return (
    <Card
      variant="outlined"
      sx={{
        border: "0.4px solid",
        borderColor: "background.border",
        boxShadow:
          "4px 2px 124px rgba(46, 53, 58, 0.02), 0px 4px 124px rgba(46, 41, 78, 0.02)",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        p: 3,
      }}
    >
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {fCurrency(amount)}
        </Typography>
        <Typography variant="subtitle2" noWrap>
          On {date ? fDate(date) : ""}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {reason}
          </Typography>
        </Box>
      </Box>
      <IconButton color="error" onClick={handleRemove}>
        <DeleteIcon />
      </IconButton>
    </Card>
  );
}

DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  loading: PropTypes.bool,
};
function DeleteModal({ open, handleClose, handleDelete, loading }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "error.main" }}>
        Please confirm you want to delete this debt?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <LoadingButton
          onClick={handleDelete}
          variant="contained"
          loading={loading}
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

DebtHistory.propTypes = {
  debts: PropTypes.string.isRequired,
  rid: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
};

AddNew.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAdd: PropTypes.func,
  loading: PropTypes.bool,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};
function AddNew({
  open,
  handleClose,
  handleAdd,
  loading,
  values,
  handleChange,
}) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle>Add a new debt</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={4} sx={{ width: 1, p: 2, px: 0.5 }}>
          <TextField
            size="small"
            type="number"
            name="amount"
            value={values.amount}
            label="Amount Owed"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&#8358;</InputAdornment>
              ),
            }}
          />
          <TextField
            size="small"
            type="date"
            name="date"
            value={values.date}
            label="Date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
          <TextField
            size="small"
            type="text"
            fullWidth
            name="reason"
            value={values.reason}
            label="Reason for debt"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows="4"
            onChange={handleChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Close
        </Button>
        <LoadingButton
          onClick={handleAdd}
          variant="contained"
          loading={loading}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default function DebtHistory({ debts, rid, fetch }) {
  const actualDebts = debts !== "" ? JSON.parse(debts) : [];
  const { addDebtOwed, removeDebtOwed } = useAuth();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toDelete, setToDelete] = useState("");
  const [values, setValues] = useState({ date: "", amount: "", reason: "" });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleAdd = async () => {
    try {
      if (values.amount) {
        setLoading(true);
        await addDebtOwed(values, rid);
        setValues({ date: "", amount: "", reason: "" });
        enqueueSnackbar(`Debt recorded successfully!.`, {
          variant: "success",
        });
        fetch();
      } else {
        enqueueSnackbar(`Please enter atleast an amount to proceed.`, {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setLoading(false);
  };
  const handleDelete = async () => {
    try {
      if (toDelete !== "") {
        setIsSubmitting(true);
        await removeDebtOwed(toDelete, rid);
        setToDelete("");
        fetch();
      } else {
        enqueueSnackbar(`Something went wrong! Please try again later.`, {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setIsSubmitting(false);
  };
  const handleRemove = (index) => {
    setToDelete(index);
    setOpenDelete(true);
  };
  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          Debts
        </Typography>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add
        </Button>
      </Stack>
      {actualDebts.length > 0 ? (
        <Grid container spacing={3}>
          {actualDebts.map((debt, index) => (
            <Grid key={index} item xs={12} md={4}>
              <SingleCard
                debt={debt}
                handleRemove={() => handleRemove(index)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShowError
          message={
            new Error(
              "This debtor does not have any debts attached at the moment!"
            )
          }
        />
      )}
      <AddNew
        open={open}
        handleClose={() => setOpen(false)}
        handleAdd={handleAdd}
        loading={loading}
        values={values}
        handleChange={handleChange}
      />
      <DeleteModal
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleDelete={handleDelete}
        loading={isSubmitting}
      />
    </Box>
  );
}
